.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ticket-post {
    min-width: 300px;
}

.ticket-list-item {
    cursor: pointer;
}
.ticket-page__info {
    /* position: sticky; */
    /* top: 20px; */
}
.badge-hover {
    transition: 0.1s;
}
.badge-hover:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.attachment-container {
    margin-top: 1em;
}
.attachment-container img {
    max-height: 300px;
    max-width: 300px;
    height: auto;
    width: auto;
    border-radius: 4px;
}

.attachment-container video {
    max-height: 300px;
    max-width: 300px;
    height: auto;
    width: auto;
    border-radius: 4px;
}

.attachment-container img:hover,
.attachment-container video:hover {
    opacity: 0.8;
}
